import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { Box } from 'components/Box/Box';
import { Hide } from 'components/Hide/Hide';
import { Icon } from 'components/Icon/Icon';
import { BodySmall } from 'components/Text/BodySmall';
import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { colors } from 'theme/theme';
import { matchSize } from 'utils/responsive';

import { Page } from './Page';
import { ArrowLink } from './Pagination.styled';
import { getResultsDescription } from './getResultsDescription';

// Number of pages before and after the current page we want to show links to
const PAGINATION_RADIUS = matchSize({
  xs: true,
})
  ? 1
  : 2;

type Props = {
  nbHits: number;
  nbPages: number;
  hitsPerPage: number;
  pageIndex: number;
  onChangePageIndex?: (pageIndex: number) => void;
  pageParam?: string;
};

export function Pagination({
  nbHits,
  nbPages,
  hitsPerPage,
  pageIndex,
  onChangePageIndex,
  pageParam = 'page',
}: Props) {
  const navigate = useDeferredNavigate();
  const location = useLocation();
  if (nbPages < 2) return null;

  const pageLink = (index: number) => {
    const params = queryString.parse(location.search);

    if (index > 0) {
      // @ts-expect-error TS(2322): Type 'number' is not assignable to type 'string | ... Remove this comment to see the full error message
      params[pageParam] = index + 1;
    } else {
      delete params[pageParam];
    }

    return `?${queryString.stringify(params)}`;
  };

  const goToPage = (index: number) => {
    navigate(pageLink(index));
    onChangePageIndex?.(index);
  };

  // Total number of page links to show
  const pageLinkCount = PAGINATION_RADIUS * 2 + 1;
  const allPages = [];

  for (let i = 0; i < nbPages; i++) {
    allPages.push(i);
  }

  const startIndex = Math.max(pageIndex - PAGINATION_RADIUS, 0);
  const endIndex = startIndex + pageLinkCount;
  const pageLinksToShow =
    endIndex > nbPages // We are at the end of the list
      ? allPages.slice(-pageLinkCount) // We are in the middle or beginning of list
      : allPages.slice(startIndex, endIndex);

  const chevronLeftDisabled = pageIndex === 0;
  const chevronRightDisabled = pageIndex === nbPages - 1;

  return (
    <Box mt="18px" pt="18px" borderTop={`1px solid ${colors.selectionGrey}`}>
      <Box
        display="flex"
        flexDirection={['column', null, null, 'row']}
        alignItems="center"
        justifyContent={['center', null, null, 'space-between']}
      >
        <BodySmall lineHeight="1">
          {getResultsDescription({
            pageIndex,
            hitsPerPage,
            nbHits,
          })}
        </BodySmall>
        <Box
          component="nav"
          display="flex"
          alignItems="center"
          justifyContent={['center', null, null, 'flex-end']}
          mt={['12px', null, null, 0]}
          flex="1 1 auto"
          data-qa-id="pagination"
        >
          <ArrowLink
            data-qa-id="pagination-link-previous"
            href={pageLink(pageIndex - 1)}
            $disabled={chevronLeftDisabled}
            tabIndex={chevronLeftDisabled ? -1 : 0}
            aria-label={getText('Previous page')}
            onClick={(e) => {
              e.preventDefault();
              goToPage(pageIndex - 1);
            }}
          >
            <Icon name="chevron-left" size={16} />
          </ArrowLink>
          {pageLinksToShow[0] > 0 && (
            <>
              <Page
                key={0}
                pageNumber={0}
                href={pageLink(0)}
                selected={false}
                onClick={(e) => {
                  e.preventDefault();
                  goToPage(0);
                }}
              />
              {pageLinksToShow[0] > 1 && (
                <Box pr={['8px', 0]} color={colors.elementGrey}>
                  ...
                </Box>
              )}
            </>
          )}
          {pageLinksToShow.map((pageNumber) => (
            <Hide
              key={pageNumber} // only show 3 pages on small mobile screens
              // otherwise paging will wrap on iPhone SE
              xs={
                pageNumber !== pageIndex &&
                pageNumber !== nbPages - 1 &&
                (pageNumber === pageLinksToShow[0] ||
                  pageNumber === pageLinksToShow[pageLinksToShow.length - 1])
              }
            >
              <Page
                key={pageNumber}
                href={pageLink(pageNumber)}
                pageNumber={pageNumber}
                selected={pageNumber === pageIndex}
                onClick={(e) => {
                  e.preventDefault();
                  goToPage(pageNumber);
                }}
              />
            </Hide>
          ))}
          {pageLinksToShow[pageLinksToShow.length - 1] < nbPages - 1 && (
            <>
              {pageLinksToShow[pageLinksToShow.length - 1] < nbPages - 2 && (
                <Box pl={['8px', 0]} color={colors.elementGrey}>
                  ...
                </Box>
              )}
              <Page
                key={nbPages - 1}
                href={pageLink(nbPages - 1)}
                pageNumber={nbPages - 1}
                selected={false}
                onClick={(e) => {
                  e.preventDefault();
                  goToPage(nbPages - 1);
                }}
              />
            </>
          )}
          <ArrowLink
            data-qa-id="pagination-link-next"
            href={pageLink(pageIndex + 1)}
            $disabled={chevronRightDisabled}
            tabIndex={chevronRightDisabled ? -1 : 0}
            onClick={(e) => {
              e.preventDefault();
              goToPage(pageIndex + 1);
            }}
            aria-label={getText('Next page')}
          >
            <Icon name="chevron-right" size={16} />
          </ArrowLink>
        </Box>
      </Box>
    </Box>
  );
}
